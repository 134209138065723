<template>
  <div>
    <v-menu
      transition="slide-x-transition"
      content-class="elevation-3 profile-menu"
      bottom
      left
      offset-y
      :close-on-content-click="false"
      v-model="menu"
    >
      <template #activator="{ on, attrs }">
        <v-btn id="profile-btn" icon v-bind="attrs" v-on="on">
          <v-icon color="white">mdi-account</v-icon>
        </v-btn>
      </template>

      <form>
        <div class="user-profile">
          <!--  -->
          <!--  -->
          <v-tabs v-model="activeTab" hide-slider>
            <!--  -->

            <!-- <v-btn>edit</v-btn>
            <v-btn>pro</v-btn> -->

            <!--  -->
            <v-tab-item id="edit-profile" class="pa-4">
              <v-card tile flat class="transparent" :disabled="busy">
                <div>
                  <v-btn large color="grey" icon @click="activeTab = 'user'"
                    ><v-icon large>chevron_left</v-icon></v-btn
                  >
                </div>
                <!--  -->
                <div class="text-center my-4 mb-9">
                  <v-hover>
                    <template v-slot:default="{ hover }">
                      <v-badge
                        :value="!!user && user.image"
                        light
                        color="grey"
                        avatar
                      >
                        <template v-slot:badge>
                          <v-icon
                            style="cursor: pointer"
                            @click="onDeletedImage"
                            size="20"
                            color="white"
                          >
                            close
                          </v-icon>
                        </template>
                        <v-avatar @click.stop="profileImage = true" size="130">
                          <ProfileImage
                            :image="user.image"
                            :size="130"
                            className="img-fluid align-self-center rounded-circle"
                            :alt="user.name"
                          />
                          <v-fade-transition>
                            <v-overlay
                              v-if="hover && !loading"
                              absolute
                              color="black"
                            >
                              <v-btn
                                @click.stop="showProfileDialog"
                                v-if="!loading"
                                icon
                                :loading="loading"
                              >
                                <v-icon style="cursor: pointer" v-if="!loading">
                                  edit
                                </v-icon>
                              </v-btn>
                            </v-overlay>
                            <v-overlay absolute color="black" v-if="loading">
                              <v-btn v-if="loading" icon :loading="loading" />
                            </v-overlay>
                          </v-fade-transition>
                        </v-avatar>
                      </v-badge>
                    </template>
                  </v-hover>
                </div>
                <!--  -->
                <v-text-field
                  v-model="name"
                  v-validate="'required|max:20|min:3'"
                  counter="20"
                  id="user_name_field"
                  v-bind="veeValidate('Name', 'Name')"
                  placeholder="Your name"
                  filled
                  @click:append="show = !show"
                >
                </v-text-field>

                <template>
                  <v-text-field
                    v-validate="'required|email'"
                    v-bind="veeValidate('Email', 'Email')"
                    id="user_email_field"
                    v-model="email"
                    placeholder="Your email address"
                    filled
                  >
                  </v-text-field>
                </template>

                <v-btn
                  :loading="busy"
                  @click="UpdateProfileInfo"
                  class="main primary mx-auto mb-3"
                  id="profile-edit-save"
                  :disabled="errors.any()"
                >
                  save
                </v-btn>
              </v-card>
            </v-tab-item>
            <v-tab-item id="user">
              <div
                class="
                  d-flex
                  flex-column flex-sm-row
                  align-center
                  py-3
                  pt-5
                  py-sm-7
                  px-4
                "
              >
                <ProfileImage
                  :image="user.image"
                  className="img-fluid align-self-center rounded-circle"
                  :size="$vuetify.breakpoint.xsOnly ? 80 : 60"
                  :alt="user.name"
                />

                <div class="mx-3 text-center text-sm-left mt-4 mt-sm-0">
                  <h3 class="font-weight-bolde mb-0">
                    {{ user.name }}
                  </h3>
                  <span class="pa-0 ma-0 text-muted">{{ user.email }}</span>
                </div>
              </div>
              <v-card class="transparent" flat tile>
                <v-list nav>
                  <v-list-item-group v-model="selectedItem" color="primary">
                    <v-list-item
                      id="my-profile"
                      @click="activeTab = 'edit-profile'"
                    >
                      <v-icon> mdi-account </v-icon>
                      <v-list-item-content>
                        <v-list-item-title class="bold-text text-capitalize">
                          My Profile
                        </v-list-item-title>
                        <v-list-item-subtitle v-text="'Edit your profile'" />
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      id="notificationSetting"
                      @click="openNotificationSetting"
                    >
                      <v-icon> mdi-earth </v-icon>
                      <v-list-item-content>
                        <v-list-item-title class="bold-text text-capitalize">
                          Notification setting
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-text="'Edit your notification setting'"
                        />
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      id="apiCredentialsBtn"
                      @click="
                        $router.push({ name: 'api-credentials' }),
                          (menu = false)
                      "
                    >
                      <v-icon left> mdi-settings </v-icon>
                      <v-list-item-content>
                        <v-list-item-title class="bold-text text-capitalize">
                          API Credentials
                        </v-list-item-title>
                        <v-list-item-subtitle
                          v-text="'Manage your API credentials  '"
                        />
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
                <div class="pa-4 d-flex">
                  <v-btn
                    id="sign-out"
                    class="main"
                    depressed
                    @click="onSIGNOUT"
                    :loading="loading"
                    >Sign Out</v-btn
                  >
                  <v-spacer />
                  <v-btn @click="onContactus" class="main">Contact us</v-btn>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs>
          <!--  -->
          <!--  -->
        </div>
      </form>
    </v-menu>
    <image-upload
      v-if="profileDialog"
      v-model="profileImage"
      :enable-resize="true"
      hide-overlay
      profile
      @close="profileDialog = false"
      @cropped-image="updateProfileImage"
    ></image-upload>

    <Modal
      v-model="showSuccessTeamSwitch"
      @close="showSuccessTeamSwitch = false"
    >
      <template #message>
        <v-row>
          <v-col class="my-4 font-size-h3 bold-text" cols="12">
            Switching teams
          </v-col>
          <v-col class="text-center" cols="12">
            <v-progress-circular indeterminate></v-progress-circular>
          </v-col>
          <v-col class="text-center" cols="12"> Please wait... </v-col>
        </v-row>
      </template>
    </Modal>
    <Modal
      @close="showNotificationSetting = false"
      v-model="showNotificationSetting"
      noPy
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :width="$vuetify.breakpoint.mdAndUp ? 860 : ''"
    >
      <template #message>
        <manage-settings
          v-if="showNotificationSetting"
          @close="closeManageSettings"
        ></manage-settings>
      </template>
    </Modal>
  </div>
</template>

<style lang="scss">
#kt_quick_user {
  overflow: hidden;
}
.v-avatar {
  border: 1px solid grey;
}
.user-profile {
  .v-tabs-bar {
    display: none !important;
  }
}
</style>

<script>
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import veeValidate from "@/mixins/veeValidate";
import {
  SIGNOUT,
  UPDATE_PROFILE,
  UPDATE_PROFILE_IMAGE,
  DELETE_PROFILE_IMAGE,
} from "@/store/users/auth.module";
import ProfileImage from "@/view/components/User/Image.vue";
import ImageUpload from "@/view/components/Common/ImageUpload";
import { destroyToken } from "@/core/services/jwt.service";
import ManageSettings from "@/view/components/User/Notification/ManageSettings.vue";

export default {
  name: "KTDropdownProfileAction",
  components: { ImageUpload, ProfileImage, ManageSettings },
  mixins: [veeValidate],
  props: {
    notificationSetting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: "user",
      overlay: false,
      notify: {
        show: false,
        message: "",
        color: "",
      },
      showNotificationSetting: false,
      showSuccessTeamSwitch: false,
      selectedItem: null,
      show: null,
      name: "",
      loading: false,
      profileDialog: false,
      profileImage: "",
      busy: false,
      email: "",
      menu: false,
    };
  },
  mounted() {
    this.name = this.user ? this.user.name : "";
    this.email = this.user.email;
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  created() {
    if (this.notificationSetting) {
      this.showNotificationSetting = this.notificationSetting;
    }
  },
  methods: {
    ...mapMutations({
      setNavigateTo: "setNavigateTo",
    }),
    showProfileDialog() {
      this.$nextTick(() => {
        this.profileDialog = true;
      });
    },
    reloadAfterSuccess() {
      this.manageTeamSetting = false;
      this.showSuccessTeamSwitch = true;
      this.$nextTick(() => {
        window.location.reload();
      });
    },
    openNotificationSetting() {
      this.showNotificationSetting = true;
      window.analytics.track(
        "Opened notification settings popup from profile menu."
      );
    },
    closeManageSettings() {
      this.showNotificationSetting = false;
      this.selectedItem = null;
      this.setNavigateTo("");
    },
    onDeletedImage() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(DELETE_PROFILE_IMAGE)
        .then((response) => {
          this.notifyUserMessage(
            response.message || "Successfully deleted your profile image"
          );

          Vue.prototype.$analytics.logEvent("user", {
            status: "success",
            action: "delete_image",
          });
          this.loading = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(
            err.message || "You are already using the default image"
          );

          Vue.prototype.$analytics.logEvent("user", {
            status: "error",
            action: "delete_image",
            message: err.message,
          });
          this.loading = false;
        });
    },
    onSIGNOUT() {
      if (this.loading) return;
      this.loading = true;
      destroyToken();
      this.$router.push({ name: "signin" }).catch(() => {});
      this.$store
        .dispatch(SIGNOUT)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    updateProfileImage(event) {
      this.menu = true;
      if (this.loading) return;
      this.loading = true;
      // let image = event.target.files[0];
      let formData = new FormData();
      formData.append("image", event);
      this.$store
        .dispatch(UPDATE_PROFILE_IMAGE, formData)
        .then((response) => {
          this.notifyUserMessage(
            response.message || "Successfully updated the image"
          );

          Vue.prototype.$analytics.logEvent("user", {
            status: "success",
            action: "update_image",
          });
          this.loading = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(
            err.message || "Something went wrong, please try again"
          );
          Vue.prototype.$analytics.logEvent("user", {
            status: "error",
            action: "update_image",
            message: err.message,
          });
          this.loading = false;
        });
    },
    async UpdateProfileInfo() {
      if (await this.validateAllFields()) {
        if (this.busy) return;
        this.busy = true;
        const dataToPost = {
          name: this.name,
          email: this.email,
        };
        this.$store
          .dispatch(UPDATE_PROFILE, dataToPost)
          .then((response) => {
            this.notifyUserMessage(
              response.message || "Successfully updated the info"
            );
            this.busy = false;
            this.activeTab = "user";
            Vue.prototype.$analytics.logEvent("user", {
              status: "success",
              action: "profile",
            });
            this.busy = false;
          })
          .catch((err) => {
            this.notifyInfoMessage(
              err.message || "Something went wrong, please try again"
            );
            Vue.prototype.$analytics.logEvent("user", {
              status: "error",
              action: "profile",
              message: err.message,
            });
            this.busy = false;
          });
      }
    },
  },
};
</script>
