<template>
  <div>
    <div v-if="loading">
      <v-row>
        <v-col v-for="i in 4" :key="i" cols="12">
          <v-skeleton-loader type="article, actions"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <div v-if="whatsNewList && whatsNewList.length">
        <v-card
          outlined
          elevation="1"
          v-for="(item, index) in whatsNewList"
          :key="index"
          class="my-4 lightBorder"
        >
          <v-card tile outlined>
            <v-card-title>
              <div>
                <v-chip
                  class="mr-2 white--text"
                  small
                  :color="$options.tagColor[item.tag]"
                >
                  {{ item.tag }}
                </v-chip>
                <span class="text-caption bold-text">
                  <vue-hoverable-date
                    :date="item.created_at"
                  ></vue-hoverable-date>
                </span>
                <div class="mt-3 font-size-h2 dark-grey-text bold-text">
                  {{ item.title }}
                </div>
              </div>
            </v-card-title>
            <v-card-text class="pb-8">
              <div class="bodyFont" v-html="item.content"></div>
            </v-card-text>
          </v-card>
          <v-row class="px-3 lightBackground" no-gutters>
            <v-col class="pb-0 mt-n8 pt-4 text-center" cols="12">
              <span
                class="mx-1"
                v-for="(emoji, eindex) in emojiRating"
                :key="eindex"
              >
                <v-hover v-slot="{ hover }">
                  <v-btn
                    small
                    class=""
                    @click.stop="(item.rate = emoji.value), sendRate(item)"
                    icon
                  >
                    <v-img
                      :src="emoji.icon"
                      color="warning"
                      contain
                      :class="
                        item.rate && item.rate !== emoji.value
                          ? 'greyscale'
                          : ''
                      "
                      width="40"
                      :height="item.rate === emoji.value || hover ? '30' : '25'"
                    ></v-img>
                  </v-btn>
                </v-hover>
              </span>
            </v-col>
            <v-col class="mt-2 mb-4" cols="12">
              <div v-if="item.showSuccess">
                <v-icon
                  v-text="'mdi-check-circle'"
                  color="success"
                  left
                ></v-icon>
                Thank you for your feedback!
              </div>
              <v-text-field
                v-else
                outlined
                hide-details
                dense
                :append-icon="item.message ? 'mdi-send' : ''"
                @click:append="postWhatsNewFeedback(item)"
                v-model="item.message"
                placeholder="Feedback"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </div>
      <div v-else>
        <v-col class="no-data-found">
          <inline-svg
            class="mt-6 svg-icon empty_icon"
            src="/media/placeholders/error.svg"
          />
          <p class="mt-4 empty-title bold-text text-dark text-center">
            No new updates found.
          </p>
          <p
            class="mt-4 mb-5 empty-sub-title font-weight-normal text-dark text-center"
          >
            <!--        Upgrade your Team now-->
          </p>
        </v-col>
      </div>
    </div>
  </div>
</template>

<script>
import {
  POST_WHATS_NEW_FEEDBACK,
  FETCH_WHATS_NEW_LIST,
} from "@/store/users/whatsNew.module.js";
import { mapGetters } from "vuex";

export default {
  tagColor: {
    new: "pink",
    improvement: "orange",
    annoucement: "success",
    news: "primary",
  },
  data() {
    return {
      emojiRating: [
        {
          icon: "/media/emoji/in-love.png",
          value: 2,
        },
        {
          icon: "/media/emoji/happy.png",
          value: 1,
        },
        {
          icon: "/media/emoji/sad.png",
          value: 3,
        },
      ],
      loading: false,
      busy: false,
    };
  },
  computed: {
    ...mapGetters({
      whatsNewList: "getWhatsNewList",
    }),
  },
  mounted() {
    this.fetchWhatsNewList();
  },
  created() {
    window.analytics.track("Opened whats new");
  },
  methods: {
    fetchWhatsNewList() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(FETCH_WHATS_NEW_LIST)
        .then(() => {
          this.$emit("updateId");
          this.loading = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.loading = false;
        });
    },
    postWhatsNewFeedback(item) {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(POST_WHATS_NEW_FEEDBACK, {
          id: item.id,
          message: item.message || "",
          rate: item.rate,
        })
        .then(() => {
          if (item.message) {
            item.showSuccess = true;
          }
          item.message = "";
          this.busy = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.busy = false;
        });
    },
    sendRate(item) {
      setTimeout(() => {
        this.postWhatsNewFeedback(item);
      }, 5000);
    },
  },
};
</script>

<style scoped>
.greyscale {
  filter: grayscale(100%);
}
</style>
