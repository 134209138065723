<template>
  <v-list two-line>
    <v-list-item-group>
      <!-- loader -->
      <div v-if="loader">
        <template v-for="i in 7">
          <v-card :key="i" class="pa-3 radius-10 mb-3" outlined>
            <v-skeleton-loader type="list-item-avatar-two-line" />
          </v-card>
        </template>
      </div>
      <!-- /loader -->
      <div
        class="d-flex flex-center text-center text-muted min-h-200px"
        v-if="noData && !loader"
      >
        <div class="no-data-found mx-auto">
          <inline-svg
            class="mt-6 svg-icon empty_icon"
            src="/media/placeholders/all_done.svg"
          />
          <p class="mt-2 empty-title bold-text text-dark text-center">
            All caught up
          </p>
          <p
            class="
              mt-2
              mb-5
              empty-sub-title
              font-weight-normal
              text-dark text-center
            "
          >
            No new invites
          </p>
        </div>
      </div>
      <v-card
        v-else
        class="mb-3 rounded-lg"
        style="overflow: hidden; position: relative"
        flat
        outlined
        v-for="(item, i) in getNotificationInviteList"
        @click="openInviteActionForm(item.id)"
        :key="i"
      >
        <span
          style="position: absolute; top: 10px; right: 14px; font-size: 12px"
        >
          <vue-hoverable-date :date="item.created_at"></vue-hoverable-date>
        </span>
        <v-list-item @click="openInviteDrawer(item)" style="height: 75px">
          <!-- if there is a badge -->
          <!-- user image if it's have an app -->
          <ProfileImage
            :image="item.from_user.image"
            :size="48"
            className="img-fluid align-self-center rounded-circle"
            :alt="item.from_user.name"
          />
          <v-list-item-content class="ml-2">
            <v-list-item-content>
              <v-list-item-title
                class="bold-text text-capitalize mb-1"
                style="font-size: 12px"
                >{{ item.from_user.name }}
              </v-list-item-title>
              <div style="font-size: 14px; max-width: 83%">
                {{ `Invited you to join ${item.team.name}` }}
              </div>
            </v-list-item-content>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-list-item-group>
    <infinate-loading-base
      :endpoint="endpoint"
      :reset="true"
      :distance="200"
      @update-loading="loader = $event"
      @setInfiniteResponse="setInfiniteResponse"
      @resetInfiniteResponse="resetInfiniteResponse"
    ></infinate-loading-base>

    <Modal v-model="showInviteDrawer" @onClose="showInviteDrawer = false">
      <template slot="message">
        <invite-action-modal
          v-if="showInviteDrawer"
          :current-invite-details="currentInvite"
          @close="showInviteDrawer = false"
        ></invite-action-modal>
      </template>
    </Modal>
  </v-list>
</template>

<script>
import Vue from "vue";
import { isEmpty, get } from "@/core/services/helper.service";
import { mapState, mapGetters, mapMutations } from "vuex";
import { ACCEPT_DECLINE, GET_ALL_INVITES } from "@/store/team/invite.module";
import ProfileImage from "@/view/components/User/Image.vue";
import InfinateLoadingBase from "@/view/components/Common/InfinateLoadingBase.vue";
import InviteActionModal from "@/view/components/Invites/ActionModal.vue";
export default {
  props: ["read"],
  components: { ProfileImage, InviteActionModal, InfinateLoadingBase },
  data() {
    return {
      showInviteDrawer: false,
      currentInvite: {},
      endpoint: GET_ALL_INVITES,
      loading: false,
      loader: false,
    };
  },
  computed: {
    ...mapState({
      dashboardInviteLoader: (state) =>
        get(state, "invite.dashboardInviteLoader", ""),
    }),
    ...mapGetters({
      currentNotifications: "userNotificationsStats",
      getNotificationInviteList: "getNotificationInviteList",
    }),
    listOfInvites: {
      get: function () {
        let data = get(this.$store.state, "invite.listOfInvites", "");
        return data;
      },
      set: function (v) {
        this.$store.commit("setListOfInvites", v);
      },
    },
    noData() {
      return isEmpty(this.getNotificationInviteList) ? true : false;
    },
  },
  methods: {
    ...mapMutations({
      updateUserPermission: "updateUserPermission",
      updateNotifications: "updateNotification",
      setNotificationInviteList: "setNotificationInviteList",
      clearNotificationInviteList: "clearNotificationInviteList",
    }),
    markAsRead() {
      // this.$emit("markAsRead");
    },
    async setInfiniteResponse(data) {
      await this.setNotificationInviteList(data.data);
    },
    resetInfiniteResponse() {
      this.clearNotificationInviteList();
    },
    openInviteActionForm(id) {
      this.id = id;
    },
    openInviteDrawer(detail) {
      this.currentInvite = detail;
      this.showInviteDrawer = true;
    },
    submitAction(actionType) {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(ACCEPT_DECLINE, {
          id: this.currentInvite.id,
          actionType,
        })
        .then((response) => {
          // to delete the current list from the list after the action happend
          this.showInviteDrawer = false;
          this.listOfInvites = this.listOfInvites.filter((e) => {
            return e.id !== this.currentInvite.id;
          });
          this.updateUserPermission(response.permissions);
          this.notifyUserMessage(response.message);
          let invites = this.currentNotifications.invites - 1;
          this.$store.commit("updateNotification", {
            alerts: this.currentNotifications.alerts,
            invites,
          });
          this.loading = false;
          this.showInviteDrawer = false;
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage(err.message || "");
          this.showInviteDrawer = false;
          this.loading = false;
          this.showInviteDrawer = false;
        });
    },
  },
  beforeDestroy() {
    this.clearNotificationInviteList();
  },
  async mounted() {
    Vue.prototype.$analytics.logEvent("user", {
      status: "open",
      action: "invites",
    });
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/variables";
.new {
  background-color: rgba($blue, 0.1) !important;
  border-color: $blue !important;
  .v-list-item__subtitle {
    color: $blue !important;
  }
}
</style>
