<template>
  <div class="d-flex flex-column flex-root">
    <Loader v-if="loaderEnabled" :logo="loaderLogo" />
    <Header class="mb-15 pb-15" />
    <v-container class="mb-15">
      <router-view class="mb-15" />
    </v-container>
    <div class="mb-15"></div>
    <Footer />
    <vue-snack-bar />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/view/layout/header/Header.vue";
import Footer from "@/view/layout/footer/Footer.vue";
import Loader from "@/view/components/Common/VueLoader.vue";

export default {
  name: "Layout",
  components: { Header, Footer, Loader },
  data() {
    return { loaderEnabled: true };
  },
  mounted() {
    setTimeout(() => {
      this.loaderEnabled = false;
    }, 2500);
  },
  computed: {
    ...mapGetters(["isAuthenticated", "layoutConfig"]),
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },
  },
};
</script>
<style lang="scss" scoped>
#hubspot-messages-iframe-container {
  z-index: 999999 !important;
}
</style>
