<template>
  <div>
    <div v-if="showStartTrialAlert">
      <v-app-bar dense flat fixed color="green">
        <v-row class="pb-2 white--text" justify="center" align="center">
          <v-col
            @click="$router.push({ name: 'plans' })"
            class="text-center cursor-pointer"
            cols="12"
          >
            Start 7-day free trial for this team 🎉
            <span style="float: right" class="text-right">
              <v-icon
                @click.stop="openHideStartTrialModal('start')"
                class="cursor-pointer"
                color="white"
                v-text="'mdi-close-circle'"
              ></v-icon>
            </span>
          </v-col>
        </v-row>
      </v-app-bar>
    </div>
    <div v-if="showActiveTrailAlert">
      <v-app-bar
        id="trailActiveAlert"
        :dense="$vuetify.breakpoint.smAndUp"
        flat
        fixed
        :color="isTrailExpiring() ? 'red' : 'green'"
      >
        <v-row class="white--text" justify="center" align="center">
          <v-col
            cols="12"
            :class="$vuetify.breakpoint.smAndDown ? 'text-left' : 'text-center'"
          >
            <span :class="$vuetify.breakpoint.smAndDown ? 'py-12' : ''">
              Your team has activated the free trial on
            </span>
            <b> {{ getTeamTrial.plan.name }} </b> plan and it is going to be
            ended
            <b :class="$vuetify.breakpoint.smAndDown ? 'py-12' : ''">
              <vue-hoverable-date
                :date="getTeamTrial.end_at"
                with-ending-dot
              ></vue-hoverable-date>
            </b>
            <span
              class="font-weight-bold cursor-pointer"
              @click="$router.push({ name: 'plans' })"
            >
              Upgrade now
              <v-icon
                class=""
                color="white"
                v-text="'mdi-trending-up'"
              ></v-icon>
            </span>
            <span
              :style="
                $vuetify.breakpoint.smAndDown
                  ? 'position: absolute; right: 0; bottom: 20px'
                  : 'float: right'
              "
              class="text-right"
            >
              <v-icon
                @click="openHideStartTrialModal('activeTrial')"
                class="cursor-pointer"
                color="white"
                v-text="'mdi-close-circle'"
              ></v-icon>
            </span>
          </v-col>
        </v-row>
      </v-app-bar>
    </div>
    <div :class="showActiveTrailAlert || showStartTrialAlert ? 'mt-10' : ''">
      <v-app-bar
        :class="showActiveTrailAlert || showStartTrialAlert ? 'my-10' : ''"
        color="primary"
        fixed
        flat
      >
        <v-container style="display: flex; align-items: center">
          <router-link to="/">
            <img
              alt="TestApp.io"
              :src="'/media/logos/icon-light.svg'"
              class="logo-default"
              content
              :class="{
                'max-h-40px': $vuetify.breakpoint.mdAndUp,
                'max-h-30px': $vuetify.breakpoint.mdAndDown,
                'max-h-20px': $vuetify.breakpoint.smAndDown,
              }"
            />
          </router-link>
          <v-menu
            v-if="getTeamName"
            transition="slide-x-transition"
            content-class="elevation-3"
            bottom
            left
            offset-y
            :close-on-content-click="false"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                id="teamSwitchBtn"
                class="text-transform-none"
                :class="$vuetify.breakpoint.smAndDown ? 'ml-4' : 'mx-6'"
                v-bind="attrs"
                v-on="on"
              >
                <span v-if="$vuetify.breakpoint.mdAndUp">
                  {{ getTeamName }}
                </span>
                <max-text v-else :text="getTeamName" :max="10"></max-text>
                <v-icon class="text-muted" right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <team-list></team-list>
          </v-menu>
          <template
            v-if="
              ![
                'welcome',
                'get-started',
                'started-team',
                'started-app',
                'started-invite',
                'started-releases',
              ].includes($route.name) && !$vuetify.breakpoint.smAndDown
            "
          >
            <v-divider vertical></v-divider>
            <router-link
              v-if="$vuetify.breakpoint.mdAndUp"
              class="ml-6 white--text bold--text"
              to="/"
            >
              Dashboard
            </router-link>
            <router-link
              v-if="$vuetify.breakpoint.mdAndUp"
              class="mx-8 white--text bold--text"
              to="/apps"
            >
              Apps
            </router-link>
            <router-link
              v-if="$vuetify.breakpoint.mdAndUp"
              class="white--text bold--text"
              to="/team"
            >
              Team
            </router-link>
          </template>
          <v-spacer />
          <v-spacer />
          <div
            v-if="$vuetify.breakpoint.mdAndUp"
            @click="openWhatsNewList"
            text
            class="white--text cursor-pointer text-transform-none mr-2"
          >
            <v-badge
              v-if="showWhatsNewBadge"
              style="z-index: 1"
              dot
              color="white"
            >
              <span class="white--text bold-text">What's New?</span>
            </v-badge>
            <span v-else class="svg-icon svg-icon-xl dark-grey-text">
              <span class="bold-text white--text">What's New?</span>
            </span>
          </div>
          <FeedbackMenu v-if="$vuetify.breakpoint.mdAndUp"></FeedbackMenu>
          <span class="d-flex">
            <v-btn icon @click="showNotificationDrawer = true">
              <v-badge
                v-if="totalNotifications"
                style="z-index: 1"
                dot
                color="white"
                :content="
                  `${totalNotifications}`.includes('-')
                    ? '0'
                    : totalNotifications
                "
              >
                <v-icon color="white">mdi-bell</v-icon>
              </v-badge>
              <span v-else class="svg-icon svg-icon-xl">
                <v-icon color="white">mdi-bell</v-icon>
              </span>
            </v-btn>
          </span>
          <span>
            <UserProfile :notification-setting="showNotificationSetting" />
          </span>
          <span>
            <v-btn
              v-if="
                $vuetify.breakpoint.smAndDown &&
                $route.path !== '/welcome' &&
                $route.path !== '/get-started'
              "
              large
              icon
              @click="menu = !menu"
            >
              <v-icon color="white">menu</v-icon>
            </v-btn>
          </span>
        </v-container>
      </v-app-bar>
      <!--      <v-navigation-drawer-->
      <!--        v-model="drawer"-->
      <!--        :width="-->
      <!--          [-->
      <!--            'app-dashboard',-->
      <!--            'apps',-->
      <!--            'app-members',-->
      <!--            'app-releases',-->
      <!--            'app-settings',-->
      <!--            'app-share-link',-->
      <!--            'app-feedback'-->
      <!--          ].includes($route.name)-->
      <!--            ? '20%'-->
      <!--            : ''-->
      <!--        "-->
      <!--        app-->
      <!--        mini-variant-width="80"-->
      <!--      >-->
      <!--        <v-row class="fill-height" no-gutters>-->
      <!--          <v-navigation-drawer-->
      <!--            color="grey"-->
      <!--            mini-variant-->
      <!--            app-->
      <!--            mini-variant-width="80"-->
      <!--            permanent-->
      <!--          >-->
      <!--            <v-list-item class="px-2">-->
      <!--              <v-list-item-avatar>-->
      <!--                <v-img src="/media/defaults/app.png/"></v-img>-->
      <!--              </v-list-item-avatar>-->
      <!--            </v-list-item>-->

      <!--            <v-divider></v-divider>-->

      <!--            <v-list dense nav>-->
      <!--              <v-list-item-->
      <!--                v-for="item in items"-->
      <!--                :key="item.title"-->
      <!--                class="pa-2"-->
      <!--                :to="item.to"-->
      <!--                link-->
      <!--              >-->
      <!--                <div class="text-center">-->
      <!--                  <v-icon>{{ item.icon }}</v-icon>-->
      <!--                  <div class="small-text">{{ item.title }}</div>-->
      <!--                </div>-->
      <!--              </v-list-item>-->
      <!--            </v-list>-->
      <!--          </v-navigation-drawer>-->

      <!--          <v-list v-if="$route.name == 'apps'" class="grow">-->
      <!--            <v-list-item class="px-2">-->
      <!--              <div class="bold-text">-->
      <!--                Recent Apps-->
      <!--              </div>-->
      <!--            </v-list-item>-->
      <!--            <v-divider></v-divider>-->
      <!--            <v-list-item-->
      <!--              v-for="item in getApps"-->
      <!--              :key="item.id"-->
      <!--              @click="-->
      <!--                $router.push({-->
      <!--                  name: 'app-dashboard',-->
      <!--                  params: { app_id: item.id }-->
      <!--                })-->
      <!--              "-->
      <!--              link-->
      <!--            >-->
      <!--              <div class="text-center">-->
      <!--                <div class="small-text">{{ item.name }}</div>-->
      <!--              </div>-->
      <!--              <v-list-item-action>-->
      <!--                <v-icon v-text="'mdi-chevron-right'"></v-icon>-->
      <!--              </v-list-item-action>-->
      <!--            </v-list-item>-->
      <!--          </v-list>-->
      <!--          <v-list-->
      <!--            v-if="-->
      <!--              [-->
      <!--                'app-dashboard',-->
      <!--                'app-members',-->
      <!--                'app-releases',-->
      <!--                'app-settings',-->
      <!--                'app-share-link',-->
      <!--                'app-feedback'-->
      <!--              ].includes($route.name)-->
      <!--            "-->
      <!--            class="grow"-->
      <!--          >-->
      <!--            <v-list-item-->
      <!--              style="display: flex; align-items: center"-->
      <!--              class="px-2"-->
      <!--            >-->
      <!--              <v-row align="center">-->
      <!--                <v-col cols="auto">-->
      <!--                  <img-->
      <!--                    :alt="appInfo.name"-->
      <!--                    class="app-img-size"-->
      <!--                    :src="getAppImage(appInfo)"-->
      <!--                    @error="replaceDefaultAppImage"-->
      <!--                  />-->
      <!--                </v-col>-->
      <!--                <v-col>-->
      <!--                  <div class="bold-text">-->
      <!--                    {{ appInfo.name }}-->
      <!--                  </div>-->
      <!--                </v-col>-->
      <!--              </v-row>-->
      <!--            </v-list-item>-->
      <!--            <v-divider></v-divider>-->
      <!--            <v-list-item-->
      <!--              v-for="item in menuList"-->
      <!--              :key="item.value"-->
      <!--              @click="-->
      <!--                $router.push({-->
      <!--                  name: item.link,-->
      <!--                  params: { app_id: $route.params.app_id }-->
      <!--                })-->
      <!--              "-->
      <!--              link-->
      <!--            >-->
      <!--              <div class="text-center">-->
      <!--                <div class="small-text">{{ item.title }}</div>-->
      <!--              </div>-->
      <!--              <v-list-item-action>-->
      <!--                <v-icon v-text="'mdi-chevron-right'"></v-icon>-->
      <!--              </v-list-item-action>-->
      <!--            </v-list-item>-->
      <!--          </v-list>-->
      <!--        </v-row>-->
      <!--      </v-navigation-drawer>-->
      <v-card tile flat class="transparent">
        <SidePanel
          :temporary="false"
          :permanent="false"
          v-model="showNotificationDrawer"
          width="500"
        >
          <Notification
            :notification-stats="userNotifications || {}"
            @close="showNotificationDrawer = false"
          />
        </SidePanel>
        <SidePanel
          :temporary="false"
          :permanent="false"
          v-model="showWhatsNewList"
          width="600"
        >
          <v-card-title>What's new</v-card-title>
          <whats-new @updateId="setNewWhatsNewId"></whats-new>
        </SidePanel>
        <v-navigation-drawer
          v-model="menu"
          app
          v-if="$vuetify.breakpoint.mdAndDown"
        >
          <v-list nav>
            <v-list-item-group v-model="selectedItem">
              <v-list-item to="/" link>
                <h3 class="bold-text">Dashboard</h3>
              </v-list-item>
              <v-list-item to="/apps" link>
                <h3 class="bold-text">Apps</h3>
              </v-list-item>
              <v-list-item to="/team" link>
                <h3 class="bold-text">Team</h3>
              </v-list-item>
              <v-list-item @click="showFeedbackForm = true">
                <h3 class="bold-text">Feedback</h3>
              </v-list-item>
              <v-list-item @click="openWhatsNewList">
                <h3 class="bold-text">
                  <v-badge
                    v-if="showWhatsNewBadge"
                    style="z-index: 1"
                    dot
                    color="primary"
                  >
                    <span class="bold-text">What's New?</span>
                  </v-badge>
                  <span v-else class="svg-icon svg-icon-xl dark-grey-text">
                    <span class="bold-text">What's New?</span>
                  </span>
                </h3>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
        <Modal
          v-model="hideStartTrialModal"
          @close="hideStartTrialModal = false"
        >
          <template #message>
            <v-row>
              <v-col cols="12">
                <div class="mt-4">
                  <v-checkbox
                    :value="startTrialAlert"
                    @change="hideStartTrialAlert()"
                    label="Don't show this again"
                  ></v-checkbox>
                </div>
              </v-col>
              <v-col cols="12">
                <v-btn
                  @click="hideStartTrialAlert('hideOnly')"
                  class="text-transform-none"
                  color="primary"
                  depressed
                >
                  Hide
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </Modal>
        <Modal
          @close="showFeedbackForm = false"
          noPy
          fullscreen
          v-model="showFeedbackForm"
        >
          <template #message>
            <feedback-form
              flat
              @close="showFeedbackForm = false"
            ></feedback-form>
          </template>
        </Modal>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import Notification from "@/view/layout/extras/dropdown/DropdownNotification.vue";
import UserProfile from "@/view/layout/extras/dropdown/DropdownProfileAction.vue";
import FeedbackForm from "@/view/components/Common/FeedbackForm";
import FeedbackMenu from "@/view/components/Common/FeedbackMenu";
import StorageService from "@/core/services/localstorage.service";
import TeamList from "@/view/components/Team/List.vue";
import { isEmpty } from "@/core/services/helper.service";
import dayjs from "dayjs";
import WhatsNew from "@/view/components/User/Notification/WhatsNew.vue";

export default {
  name: "KTHeader",
  data() {
    return {
      isEmpty,
      menu: false,
      drawer: true,
      selectedItem: 1,
      showNotificationSetting: false,
      emojiRating: [
        {
          icon: "mdi-emoticon-cool",
          value: 3,
        },
        {
          icon: "mdi-emoticon-excited",
          value: 2,
        },
        {
          icon: "mdi-emoticon-cry",
          value: 0,
        },
      ],
      showNotificationDrawer: false,
      hideStartTrialModal: false,
      showFeedbackForm: false,
      dashboard: "",
      feedBackRating: 0,
      hideStartTrial: false,
      startHideAction: false,
      lastNewId: "",
      hideActiveTrail: false,
      reconnecting: false,
      // items: [
      //   { title: "Dashboard", icon: "mdi-view-dashboard", to: "/" },
      //   { title: "Apps", icon: "mdi-apps", to: "/apps" },
      //   { title: "Members", icon: "mdi-account-group", to: "/team/members" },
      //   { title: "Storage", icon: "mdi-database", to: "/team/storage" },
      //   {
      //     title: "Integrations",
      //     icon: "mdi-settings",
      //     to: "/team/integrations"
      //   }
      // ],
      // menuList: [
      //   {
      //     title: "Dashboard",
      //     link: "app-dashboard",
      //     value: 0
      //   },
      //   {
      //     title: "Releases",
      //     link: "app-releases",
      //     value: 1
      //   },
      //   {
      //     title: "Installs",
      //     link: "app-installs",
      //     value: 2
      //   },
      //   {
      //     title: "Members",
      //     link: "app-members",
      //     value: 3
      //   },
      //   {
      //     title: "Chat",
      //     link: "message-box",
      //     value: 4
      //   },
      //   {
      //     title: "Settings",
      //     link: "app-settings",
      //     value: 5
      //   },
      //   {
      //     title: "Feedback",
      //     link: "release-feedback",
      //     value: 6
      //   },
      //   {
      //     title: "Share",
      //     link: "share-link",
      //     value: 7
      //   }
      // ],
      mini: true,
      showWhatsNewList: false,
    };
  },
  components: {
    FeedbackMenu,
    FeedbackForm,
    Notification,
    UserProfile,
    WhatsNew,
    TeamList,
  },
  computed: {
    ...mapGetters({
      getApps: "getApps",
      appInfo: "getAppInfo",
      userNotifications: "userNotificationsStats",
      getNavigateTo: "getNavigateTo",
      getTeamName: "getTeamName",
      getTeamId: "getTeamId",
      getRedirectionUrl: "getRedirectionUrl",
      isSocketDisconnected: "getIsSocketDisconnected",
      getTeamTrial: "getTeamTrial",
      getIsUserDisabled: "getIsUserDisabled",
      userRoles: "userRoles",
      getWhatsNewLastReadId: "getWhatsNewLastReadId",
    }),
    ...mapGetters(["layoutConfig", "getClasses"]),
    ...mapState({
      user: (state) => state.auth.user,
    }),
    showStartTrialAlert() {
      return !!(
        isEmpty(this.getTeamTrial) &&
        !this.hideStartTrial &&
        !this.startTrialAlert &&
        !!this.getTeamId &&
        !this.getIsUserDisabled
      );
    },
    showActiveTrailAlert() {
      return (
        !isEmpty(this.getTeamTrial) &&
        !this.hideActiveTrail &&
        this.getTeamTrial.status === "active"
      );
    },
    startTrialAlert: {
      get: function () {
        return (
          StorageService.getItem("alert") &&
          StorageService.getItem("alert").start_trial
        );
      },
      set: function (v) {
        const alert = StorageService.getItem("alert");
        StorageService.setItem("alert", {
          ...alert,
          start_trial: v,
        });
      },
    },
    isActiveApp() {
      return [
        "app",
        "app-dashboard",
        "releases",
        "timeline",
        "members",
      ].includes(this.$route.name);
    },
    lastNewIdFromLocalStore() {
      return this.lastNewId || StorageService.getItem("last_whats_new_read_id");
    },

    showWhatsNewBadge() {
      return (
        this.getWhatsNewLastReadId &&
        this.lastNewIdFromLocalStore &&
        this.getWhatsNewLastReadId !== this.lastNewIdFromLocalStore
      );
    },

    totalNotifications() {
      if (this.userNotifications.alerts + this.userNotifications.invites > 99) {
        return "99+";
      } else {
        return this.userNotifications.alerts + this.userNotifications.invites;
      }
    },
  },
  watch: {
    getTeamTrial(val) {
      if (!isEmpty(val) && val.status !== "active") {
        this.hideStartTrial = true;
      }
    },
    startTrialAlert(val) {
      if (val) {
        this.hideStartTrialAlert();
      }
    },
    getTeamId(val) {
      if (val) {
        this.hideStartTrial = false;
      } else {
        this.hideStartTrial = true;
      }
    },
  },
  created() {
    if (this.getNavigateTo && this.getNavigateTo === "notification_settings") {
      window.analytics.track(
        "Opened notification settings popup from redirected url."
      );
      this.showNotificationSetting = true;
    }
    if (this.getRedirectionUrl && !isEmpty(this.getRedirectionUrl)) {
      this.$router.push(this.getRedirectionUrl);
      this.setRedirectionUrl({});
    }
    if (isEmpty(this.userRoles)) {
      this.getUserInfo();
    }
  },
  mounted() {
    if (
      !isEmpty(this.getTeamTrial) &&
      this.getTeamTrial.status === "active" &&
      !!this.activeTrialAlert
    ) {
      this.hideActiveTrail = false;
    }
    this.hideActiveTrail = !!this.activeTrialAlert;
    if (!isEmpty(this.getTeamTrial)) {
      this.hideStartTrial = true;
    }
    if (!this.getTeamId) {
      this.hideStartTrial = true;
      // this.hideActiveTrail = true;
    }
    this.hideStartTrial = !!this.startTrialAlert;
  },

  methods: {
    ...mapMutations({
      setRedirectionUrl: "setRedirectionUrl",
    }),
    openHideStartTrialModal(type) {
      if (type === "start") {
        this.startHideAction = true;
      }
      this.hideStartTrialModal = true;
    },
    getUserInfo() {
      this.$store
        .dispatch("getUserRoles")
        .then(() => {
          if (this.getTeamId) {
            this.connectToSocket();
          }
        })
        .catch((err) => {
          this.notifyErrorMessage({ message: err.message });
        });
    },
    hideStartTrialAlert(hideOnly) {
      if (!hideOnly && this.startHideAction) {
        this.startTrialAlert = "true";
      } else if (!hideOnly) {
        this.activeTrialAlert = "true";
      }
      this.hideActiveTrail = true;
      this.hideStartTrial = true;
      this.hideStartTrialModal = false;
    },
    isTrailExpiring() {
      const expiryDate = new Date(
        `${dayjs(this.getTeamTrial.end_at * 1000)}`
      ).getTime();
      const currentDate = new Date().getTime();
      const diffDate = Math.ceil(
        (expiryDate - currentDate) / (1000 * 3600 * 24)
      );
      return diffDate <= 3;
    },
    openWhatsNewList() {
      this.showWhatsNewList = true;
    },
    setNewWhatsNewId() {
      this.lastNewId = StorageService.getItem("last_whats_new_read_id");
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/sass/variables";
.menu-btn.v-btn {
  min-width: 45px !important;
  height: 45px !important;
  padding: 0 !important;
  background-color: white !important;
  border: 2px solid rgba($grey, 0.5);
  border-radius: 8px;
  color: darken($grey, 10%);
  &::before {
    display: none;
  }
}
.v-toolbar__content {
  padding-right: 0;
  padding-left: 0;
}
</style>
