<template>
  <v-menu
    v-model="showFeedbackForm"
    :close-on-content-click="false"
    left
    nudge-bottom="40"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-on="on" icon @click="showNotificationDrawer = true">
        <v-icon color="white">mdi-bullhorn</v-icon>
      </v-btn>
    </template>
    <FeedbackForm @close="showFeedbackForm = false"></FeedbackForm>
  </v-menu>
</template>

<script>
import veeValidate from "@/mixins/veeValidate";
import FeedbackForm from "@/view/components/Common/FeedbackForm";
export default {
  components: { FeedbackForm },
  mixins: [veeValidate],
  data() {
    return {
      emojiRating: [
        {
          icon: "/media/emoji/in-love.png",
          value: 2,
        },
        {
          icon: "/media/emoji/happy.png",
          value: 1,
        },
        {
          icon: "/media/emoji/sad.png",
          value: 3,
        },
      ],
      socialLinks: [
        {
          title: "Slack",
          link: "",
          icon: "mdi-slack",
        },
        {
          title: "Telegram",
          link: "",
          icon: "mdi-telegram",
        },
        {
          title: "Twitter",
          link: "",
          icon: "mdi-twitter",
        },
        {
          title: "Linkedin",
          link: "",
          icon: "mdi-linkedin",
        },
        {
          title: "Instagram",
          link: "",
          icon: "mdi-instagram",
        },
      ],
      showFeedbackForm: false,
      busy: false,
      feedback: {
        message: "",
        rate: 0,
      },
    };
  },
  watch: {
    showFeedbackForm(val) {
      if (!val) {
        this.feedback = {
          message: "",
          rate: 0,
        };
      }
      this.busy = false;
      this.$validator.errors.clear();
    },
  },
};
</script>

<style scoped></style>
